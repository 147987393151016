import React, { useState, useEffect } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Input } from '@mui/material';

import {
    message
} from "antd";

//import { useSelector, useDispatch } from "react-redux";

function Products(props) {
//class Products extends Component {
    const history = useHistory();
    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    
    const [isLoaderOpen, setIsLoaderOpen] = useState(true);
    const [productList, setProductList] = useState([]);
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API_URL);

    const [productCartList, setProductCartList] = useState(JSON.parse(localStorage.getItem('itemAddedToCart')));
    //const dispatch = useDispatch();

    useEffect(() => {
        getListofProducts();
    }, [])

 

   
    const getListofProducts = async () => {
        const requestType = 'listOfProducts';
        fetch(`${apiUrl}/products/listProducts.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType
            })
        })
            .then(res => res.json())
            .then(json => {
                if (json.status === 200) {
                    setProductList(json.data);
                    setIsLoaderOpen(false)
                    
                } else {
                    setProductList([])
                    setIsLoaderOpen(false)
                }
            })
    }


   
const onCLickChangeValueOthers =async(item) => {
    let idd = `noofquantity${item.product_cost}`;
    var quantityVal = document.getElementById(idd).value;
    if((item.product_cost === '4' && (quantityVal < 10 || quantityVal > 50) ) || item.product_cost === '30' && (quantityVal < 1 || quantityVal > 5) ){
        message.warning('Please select validate quantity to proceed!');
    }else {
        let _productCartList = productCartList ? productCartList : [];
        item['quantity'] = quantityVal;
        if(_productCartList){
            _productCartList = _productCartList.filter(obj => obj.id !== item.id).concat(item)
        }else{
            _productCartList.push(item)
        }
        
        //_productCartList.push(item)
        
        setProductCartList(_productCartList)
        localStorage.setItem('itemAddedToCart', JSON.stringify(_productCartList));
        //window.location.protocol + "//" + window.location.host + window.location.pathname + '#landingPage';
        
        
        history.push({
            //pathname: `/`, /* this path field is based on your project */
            state: _productCartList ? JSON.stringify(_productCartList) : [] /* pass state data to app page */,
          });


        //window.location.reload(true);

    }
        // let _productCartList = productCartList ? productCartList : [];
        // item['quantity'] = 1;
        // if(_productCartList){
        //     _productCartList = _productCartList.filter(obj => obj.id !== item.id).concat(item)
        // }else{
        //     _productCartList.push(item)
        // }
        // setProductCartList(_productCartList)
        // localStorage.setItem('itemAddedToCart', JSON.stringify(_productCartList));
        // window.location.reload(true);
        message.success('Item is added successfully!');

        if(window.location.pathname === '/inventory'){
            window.location.reload(true);
        }
}

    const onCLickChangeValue = async(item) => {
        let _productCartList = productCartList ? productCartList : [];
        //  if (userExists(item.id).length > 0) {
        //     console.log('_ item', item)
        //     item['quantity'] = userExists(item.id).length + 1;
            

        // }let 

        //let filrert = _productCartList.filter(obj => obj.id === item.id);
        // item['quantity'] = filrert.length > 0 ? filrert[0].quantity : 0 + 1;
        item['quantity'] = 1;
        if(_productCartList){
            _productCartList = _productCartList.filter(obj => obj.id !== item.id).concat(item)
        }else{
            _productCartList.push(item)
        }
        
        //_productCartList.push(item)
        
        setProductCartList(_productCartList)
        localStorage.setItem('itemAddedToCart', JSON.stringify(_productCartList));
        
        history.push({
            //pathname: `/`, /* this path field is based on your project */
            state: _productCartList ? JSON.stringify(_productCartList) : [] /* pass state data to app page */,
          });

          console.log('window.location.pathname', window.location.pathname)
        // this.props.history.push( {pathname: "/welcome",
        //     state: { employee:"Steven" }});
        message.success('Item is added successfully!');

        if(window.location.pathname === '/inventory'){
            window.location.reload(true);
        }
    };


    function userExists(id) {
        return productCartList.filter(function (el) {
            return el.id === id;
        });
    }

    // function upsert(array, element) { // (1)
    //     const i = array.findIndex(_element => _element.id === element.id);
    //     if (i > -1) array[i] = element; // (2)
    //     else array.push(element);
    // }


    //const onCLickChangeValue = async (item) => {

        //let _productCartList = productCartList;
        // console.log('item', userExists(item.id))
        // if (userExists(item.id).length > 0) {
        //     console.log('_ item', item)
        //     item['quantity'] = userExists(item.id).length + 1;
            

        // }
        // upsert(_productCartList, item)
        //  _productCartList = _productCartList.filter(obj => obj.id !== item.id).concat(item)
        // _productCartList.push(item)
        // setProductCartList(_productCartList)
        // console.log('_productCartList', _productCartList)
        // localStorage.setItem('itemAddedToCart', JSON.stringify(_productCartList));
    //};



    return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={isLoaderOpen} style={{ zIndex: '9999' }} >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <section className="classes-section">
                    <div className="container">
                        <div className="sec-title">
                            <h2>Our BounceHouse Mania Products</h2>
                            <p>Home of the affordable bounce house book today!</p>
                        </div>
                        <div className="classes-sec">
                            <div className="row classes-carousel">

                                {productList.map((item, idx) => {
                                    let additionalAmt = 40;
                                    if(item.product_type === 'Bounce House'){
                                        return <div className="col-lg-3">
                                        <div className="classes-col wow fadeInUp" data-wow-duration="1000ms">
                                            <div className="class-thumb"><img src={item.images_uploaded[0].images} style={{ width: "370px !important", height: "320px" }} alt="" className="w-100" />
                                                <a href={`/product-details?id=${item.id}`} title="" className="crt-btn">$<span style={{textDecoration:'line-through'}}>{parseInt(item.product_cost) + parseInt(additionalAmt)}</span>/{item.product_cost}</a>
                                            </div>
                                            <div className="class-info">
                                                <h3><a href={`/product-details?id=${item.id}`}  title="">{item.product_name}</a></h3>
                                                <span>Delivery Free in: </span> <span> {item.delivery_free_in}</span>

                                                <Button className="addToCartbtn" onClick={() => onCLickChangeValue(item)} >Add to Cart</Button>
                                                {/* <div className="productListCost">
                                                    <strong className="price">${item.product_cost}</strong>
                                                </div> */}
                                            </div>
                                        </div>
                                        </div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="classes-section">
                    <div className="container">
                        <div className="sec-title">
                            <h2>Our Other Products</h2>
                            <p>Home of the affordable bounce house book today!</p>
                        </div>
                        <div className="classes-sec">
                            <div className="row classes-carousel">

                                {productList.map((item, idx) => {
                                    if(item.product_type === 'Other'){
                                        return <div className="col-lg-3">
                                        <div className="classes-col wow fadeInUp" data-wow-duration="1000ms">
                                            <div className="class-thumb"><img src={item.images_uploaded[0].images} style={{ width: "370px !important", height: "320px" }} alt="" className="w-100" />
                                                <a href={`/product-details?id=${item.id}`} title="" className="crt-btn">${item.product_cost}</a>
                                            </div>
                                            <div className="class-info">
                                                <h3>{item.product_name}</h3>
                                                 <span> {item.description}</span>
                                                 <label for="noofquantity">No  of Quantity:</label>
                                                 <Input type="number" id={`noofquantity${item.product_cost}`} minlength="2" maxlength="8" size="2" style={{marginBottom: '10px', marginLeft: '8px', width: '68%'}} />
                                                <Button className="addToCartbtn" onClick={() => onCLickChangeValueOthers(item)} >Add to Cart</Button>
                                                {/* <div className="productListCost">
                                                    <strong className="price">${item.product_cost}</strong>
                                                </div> */}
                                            </div>
                                        </div>
                                        </div>
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );

}
export default Products;
//export default Products;