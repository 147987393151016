import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CarRentalIcon from '@mui/icons-material/CarRental';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Cart from './components/cart'
import Products from './products';
import About from './about'
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
class Home extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            carList: [],
            isLoaderOpen: false,
            displayMobileMenu: 'unset',
            visibilityMobileMenu: 'hidden',
            countt: 0,
            popupStatus: JSON.parse(localStorage.getItem('itemAddedToCart')) && JSON.parse(localStorage.getItem('itemAddedToCart')).length != 0 ? false : true,
            dashboardContent: {},
            apiUrl: process.env.REACT_APP_API_URL,
            cartProducts: []
        };
        this.listbannerAndPopup();

        //console.log(JSON.parse(props.location.state));
        //setToken(props.location.state.value); // Shows error message 'Property location does not exist on type {children?: ReactNode}'
       // console.log(token);

        //const history = useHistory();
        // const location = useLocation();
        // const currentDataFromCheckoutPage = location.state;
        // // data pass show here
        // console.log('currentDataFromCheckoutPage', currentDataFromCheckoutPage);
        // console.log('ffff', JSON.parse(localStorage.getItem('itemAddedToCart')).length > 0)
        
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState(
            {cartProducts: JSON.parse(nextProps.location.state)}
        )
      //console.log(this.props.location.state);
    }

    listbannerAndPopup = async() => {
        this.setState({tableLoading: true})
        const requestType = 'fetchDashboardContent';
        fetch(`${this.state.apiUrl}/dashboard/dashboardContent.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                requestType
            })
        })
        .then(res => res.json())
        .then(json => {
            this.setState({tableLoading: false})
            if (json.status === 200) {
                this.setState({
                    dashboardContent: json.data[0],
                    isLoaderOpen: false
                });
            } else {
                this.setState({
                    dashboardContent: {},
                    isLoaderOpen: false
                });
            }
        })
    }


    handlePopup = async () => {
        this.setState({ popupStatus: false })

    }


    render() {

        window.addEventListener('storage', () => {
            // When local storage changes, dump the list to
            // the console.
        });

        const popupStyle = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #f37234',
            boxShadow: 24,
            p: 4,
            backgroundImage: "url('/pageImages/elements.png')",
            backgroundPosition: "top"
        };


        const onMobileMenuClick = () => {
            let _count = this.state.countt + 1;
            var is_even = function (x) {
                return !(x % 2);
            }
            if (is_even(_count)) {
                this.setState({
                    countt: _count,
                    displayMobileMenu: 'unset',
                    visibilityMobileMenu: 'hidden'
                })
            } else {
                this.setState({
                    countt: _count,
                    displayMobileMenu: 'contents',
                    visibilityMobileMenu: 'visible'
                })
            }
        }

        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="main-section" id='landingPage'>
                    <header>
                        <div className="container">
                            <div className="header-content d-flex flex-wrap align-items-center">
                                <div className="logo"><a href="/home" title="">
                                    <img
                                        src="BounceMan.png" alt="" style={{ width: "23%" }} />
                                </a>
                                </div>
                                <ul className="contact-add d-flex flex-wrap">
                                    <li>
                                        <div className="contact-info">

                                        </div>
                                    </li>
                                    <li>
                                        <div className="contact-info">
                                            <img src="/pageImages/icon1.png" alt="" />
                                            <div className="contact-tt">
                                                <h4>Call</h4>
                                                <span>(440) 305-7009</span>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="contact-info">
                                            <img src="/pageImages/icon3.png" alt="" />
                                            <div className="contact-tt">
                                                <h4>Email</h4>
                                                <span>bookings@bouncehousemania.com</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <span className="mobileCartSec">
                                    <Cart cartList={this.state.cartProducts} />
                                </span>
                                <div className="menu-btn" onClick={onMobileMenuClick}><a href="#"><span className="bar1"></span> <span className="bar2"></span> <span
                                    className="bar3"></span></a></div>
                            </div>
                            <div className="navigation-bar d-flex flex-wrap align-items-center">
                                <nav>
                                    <ul>
                                        <li><a className="active" href="/home" title="">Home</a></li>
                                        <li><a className="" href="/inventory" title="">Inventory</a></li>
                                        {/* <li><a className="" href="/cart-checkout" title="">Book Now</a></li> */}
                                        <li><a className="" href="/about-us" title="">About Us</a></li>
                                        <li><a className="" href="/contact-us" title="">Contact</a></li>
                                    </ul>
                                </nav>
                                <Cart cartList={this.state.cartProducts} />
                                <ul className="social-links ml-auto">
                                    <li><a href="https://m.facebook.com/bouncemania216/" target="_blank" title=""><FacebookIcon /></a></li>
                                    <li><a href="https://www.instagram.com/?hl=en" target="_blank" title=""><InstagramIcon /></a></li>
                                    <li><a href="https://mobile.twitter.com/bouncemania216" target="_blank" title=""><TwitterIcon /></a></li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <div className="responsive-menu" style={{ display: this.state.displayMobileMenu, visibility: this.state.visibilityMobileMenu }}>
                        <ul>
                            <li><a href="/home" title="/home">Home</a></li>
                            <li><a className="" href="/inventory" title="">Inventory</a></li>
                            {/* <li><a className="" href="/cart-checkout" title="">Book Now</a></li> */}
                            <li><a className="" href="/about-us" title="">About Us</a></li>
                            <li><a className="" href="/contact-us" title="">Contact</a></li>
                        </ul>
                    </div>
                    <section className="main-banner">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-7">
                                    <div className="banner-text wow fadeInLeft" data-wow-duration="1000ms">
                                        <h2>{this.state.dashboardContent.banner_header} <span>Bring Fun Life To Your Kids</span></h2>
                                        <p>{this.state.dashboardContent.banner_content}</p>
                                        {/* <button className="bookNowLandingSection">Book Now</button> */}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className="banner-img wow zoomIn" data-wow-duration="1000ms">
                                        <img src="/pageImages/banner-img.png" alt="" />
                                    </div>
                                    <div className="elements-bg wow zoomIn" data-wow-duration="1000ms"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <h2 className="main-title">Mania</h2>
                </div>
                <Products />
                <About />

                <Modal
                    open={this.state.popupStatus}
                    onClose={this.handlePopup}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={popupStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'#e7343d'}}>
                            {this.state.dashboardContent.popup_header}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {this.state.dashboardContent.popup_content}
                        </Typography>
                    </Box>
                </Modal>
            </React.Fragment>
        );
    }

}

export default Home;