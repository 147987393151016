import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './pages/login/index';
import Signup from './signup';

import HomeNew from './pages/website/homeNew';
import Header from './pages/website/components/header'
import Footer from './pages/website/components/footer'

import Home from './pages/website/home';
import About from './pages/website/about'
import LoginWebsite from './pages/website/LoginWebsite'
import productDetails from './pages/website/productDetails'
import checkout from './pages/website/checkout';

import checkOutSuccess from './pages/website/stripe/checkOutSuccess';
import pdfDownload from './pages/website/stripe/pdfDownload';

import registerServiceWorker from './registerServiceWorker';
import Products from './pages/website/products'

import contactUs from './pages/website/contactUs'

/* Here we will create our routes right off the bat in order to 
prevent the user from getting very far in our app without authentication. */
ReactDOM.render(
    <Router>
        {
            window.location.pathname != '/' && window.location.pathname != '/home' && window.location.pathname != '/pdfDownload' &&  <Header />
        }
        <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/contact-us" component={contactUs} />
            <Route exact path="/login" component={LoginWebsite} />
            <Route exact path="/product-details" component={productDetails} />
            <Route exact path="/cart-checkout" component={checkout} />
            <Route exact path="/strip-payment-success" component={checkOutSuccess} />
            <Route exact path="/pdfDownload" component={pdfDownload} />
            <Route exact path="/inventory" component={Products} />

            
            
            {/* <Route exact path="/HomeNew" component={HomeNew} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} /> */}
        </div>
        <Footer />

    </Router>, document.getElementById('root'));
registerServiceWorker();
